import React, { Component } from 'react'; //different
//import './style.css';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var settings = {
    dots: true,
    infinite: true,
    slidesToShow: (window.innerWidth <= 760) ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear"
};

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: null,
            point: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);

    }
    handlePageChange(pageNumber) {
        //////console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });

        instance
            .get("/api/v1/rewardlist", {
                params: {
                    Cat_ID: 0,
                    page: pageNumber
                }
            })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
    }

    handleClick = async (e) => {
        this.setState({
            activePage: 1,
            isActive: true,
            activeTab: 0
        });
        instance.get("/api/v1/rewardlist", {
            params: {
                Cat_ID: e.target.id,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
        this.setState({
            isActive: false
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }


    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            this.setState({
                user_id: decoded.message.playerid,
                playerid: decoded.message.member_id,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    async componentDidMount() {
        var token = localStorage.getItem('auth_token');
        const search = window.location.href;
        const params = new URLSearchParams(search);
        //////console.log(params);
        this.checklogin();
        await instance.post("/api/v1/tabgamelist", {
            state: "9",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message
                    });
                }
                else {
                }
            });

    }

    render() {
        return (
            <div>
                <div
                    className="flicking-viewport"
                    style={{
                        userSelect: "none",
                        WebkitUserDrag: "none",
                        touchAction: "pan-y"
                    }}
                >
                    <Slider {...settings}>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-2}
                            aria-hidden="true"
                            tabIndex={-1}

                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/Teenoi888-Banner-1.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-1}
                            aria-hidden="true"
                            tabIndex={-1}

                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/Teenoi888-Banner-2.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-1}
                            aria-hidden="true"
                            tabIndex={-1}
                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/Teenoi888-Banner-3.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-1}
                            aria-hidden="true"
                            tabIndex={-1}
                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/Teenoi888-Banner-4.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-1}
                            aria-hidden="true"
                            tabIndex={-1}
                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/Teenoi888-Banner-5.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-1}
                            aria-hidden="true"
                            tabIndex={-1}
                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/Teenoi888-Banner-6.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                    </Slider >

                </div>
            </div>

        );
    }
}
export default App;
